import React, { Component } from "react";
import { Container, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import globalVar from "../globalVar";

export class Uploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploads: [],
      isLoading: true, 
      error: null, 
    };
  }

  componentDidMount() {
    this.fetchUploads();
  }

  fetchUploads() {
    const requestOptions = {
      method: "POST", // Use POST instead of GET
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ latestRecordCount: 4 }), // Send required parameters in the body
    };

    fetch(`${globalVar.url}lectures`, requestOptions) // Use globalVar.url for the API URL
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 1) {
          this.setState({ uploads: data.data, isLoading: false });
        } else {
          throw new Error("Unexpected response format");
        }
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
        console.error("Error fetching uploads:", error);
      });
  }

  render() {
    const { uploads, isLoading, error } = this.state;

    return (
      <section className="section section-uploads">
        <Container>
          <h2 className="title mb-4">New Uploads</h2>
          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <div className="text-center text-danger">
              <p>Error loading uploads: {error.message}</p>
            </div>
          ) : (
            <Row>
              {uploads.map((upload) => (
                <Col md={3} key={upload.videoId} className="mb-4">
                  <div className="upload-box">
                    <Image
                      className="w-100"
                      src={upload.video_thumbnail || "https://via.placeholder.com/150"}
                      alt={upload.video_Name}
                    />
                    <div className="upload-content mt-2">
                      <div className="subtitle">{upload.exam_Name}</div>
                      <div className="title font-weight-bold">{upload.video_Name}</div>
                      <div className="mt-2">
                        <Button
                          variant="primary"
                          href={`https://www.youtube.com/watch?v=${upload.videoId}`}
                          target="_blank"
                          className="w-100"
                        >
                          Watch Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </section>
    );
  }
}

export default Uploads;
