import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

export class Course extends React.Component {
    static propTypes = {};

    render() {
        return (
            <>
                <div
                    className="course-box team-member"
                    key={this.props.id}
                    onClick={() => this.props.instructorModalClick()}
                >
                    <div className="overflow-hidden rounded-lg course-thumbnail h-[500px]">
                        <Image
                            className="object-center w-full h-full"
                            src={'https://www.urduitacademy.com/team/' + this.props.img}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "./assets/images/courses/course-thumbnail.jpg";
                            }}
                            alt={this.props.name}
                        />
                    </div>
                    {this.props.name && (
                        <>
                            <div className="mt-3 text-center course-title d-flex justify-content-between">
                                <span className="text-lg font-semibold">{this.props.name}</span>
                                {this.props.sequance === 1 && (
                                    <span>
                                        <span
                                            className="ratio-1"
                                            style={{ fontWeight: 'normal', fontSize: '80%' }}
                                        >
                                            1:1
                                        </span>
                                    </span>
                                )}
                            </div>
                        </>
                    )}
                    {this.props.work && (
                        <>
                            <div className="text-sm text-gray-600 designation">{this.props.work}</div>
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default Course;
