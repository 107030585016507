import React from "react";
import VolImg from "../assets/keyboard-typing.png";   
import ThumbUp from "../assets/Thumb-Up.png";   
import Checked from "../assets/Checked-User.png";   
import Categorize from "../assets/Categorize.png";   
import Groups from "../assets/Groups.png";   
import Ratings from "../assets/Ratings.png";   
import Medal from "../assets/Medal.png";   
import ThumbUps from "../assets/clock.png";  
import WorkAnywhere from "../assets/location.png";  
import Environment from "../assets/balo.png";  
import TeachEnjoy from "../assets/ball.png";  
import HelpOthers from "../assets/hands.png";  

function VolunteerPage() {
  return (
    <div>
      <div
        className="relative bg-cover bg-center h-screen flex items-center justify-center"
        style={{
          backgroundImage: `url(${VolImg})`, // Correctly use the image as a background
        }}
      >
        {/* Overlay for dark effect */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Text content */}
        <div className="relative text-center text-white">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Come and Join our Team
          </h1>
          <p className="text-lg md:text-xl mb-6">
            We’re passionate about IT, and about enabling our team to teach
          </p>
          <button className="px-6 py-2 outline  text-white rounded-full font-medium hover:bg-gray-200   hover:text-black transition duration-300">
            Apply as Volunteer
          </button>
        </div>
      </div>

      <div className="bg-teal-500 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-white text-4xl font-bold mb-4">Our Aims</h2>
          <p className="text-white text-lg mb-10">
            We follow our aims to achieve our goals
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Card 1 */}
            <div className="flex flex-col items-center text-white">
              <img src={ThumbUp} alt="Card 1" className="w-32 h-32 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Fair Go</h3>
              <p className="text-center">
                Everyone deserves an equal chance and fair opportunity. At work,
                on our site we strive to be fair and consistent in everything we
                do.
              </p>
            </div>

            {/* Card 2 */}
            <div className="flex flex-col items-center text-white">
              <img src={Checked} alt="Card 1" className="w-32 h-32 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                The Right People, The Right Environment
              </h3>
              <p className="text-center">
                We look for smart, curious, capable people with skill, passion,
                and integrity. We trust them and give them the space to act and
                succeed. We support them to grow and develop.
              </p>
            </div>

            {/* Card 3 */}
            <div className="flex flex-col items-center text-white">
              <img src={Groups} alt="Card 1" className="w-32 h-32 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                When Students Succeed, we Succeed
              </h3>
              <p className="text-center">
                The students are our backbone. We always try to get involved and
                talk to the students to give them the best we can.
              </p>
            </div>

            {/* Card 4 */}
            <div className="flex flex-col items-center text-white">
              <img src={Categorize} alt="Card 1" className="w-32 h-32 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                Diverse and Inclusive
              </h3>
              <p className="text-center">
                We are welcoming, respectful, and supportive at work, on our
                site and in our team.
              </p>
            </div>

            {/* Card 5 */}
            <div className="flex flex-col items-center text-white">
              <img src={Medal} alt="Card 1" className="w-32 h-32 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Focus on Results</h3>
              <p className="text-center">
                We come to work with clear, fair, and challenging goals,
                focusing on doing the right things and doing them well.
              </p>
            </div>

            {/* Card 6 */}
            <div className="flex flex-col items-center text-white">
              <img src={Ratings} alt="Card 1" className="w-32 h-32 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                Not Just the Bottom Line
              </h3>
              <p className="text-center">
                We are building a sustainable environment that shares skills for
                many, and not for some.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-4">Benefits</h2>
          <p className="text-gray-600 text-lg mb-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
            {/* Card 1 */}
            <div className="flex flex-col items-center">
              <img
                src={ThumbUps}
                alt="Flexible Hours"
                className="w-32 h-32 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">Flexible Hours</h3>
              <p className="text-gray-600 text-center">
                So you want to work but have a tight schedule? No worries. We
                allow you to work with ease and flexible hours. Just a few hours
                a day helping society can do wonders.
              </p>
            </div>

            {/* Card 2 */}
            <div className="flex flex-col items-center">
              <img
                src={WorkAnywhere}
                alt="Work From Anywhere"
                className="w-32 h-32 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">Work From Anywhere</h3>
              <p className="text-gray-600 text-center">
                No matter where you live, you can work with us. You can always
                help by just being at home. Freedom is a beautiful thing.
              </p>
            </div>

            {/* Card 3 */}
            <div className="flex flex-col items-center">
              <img
                src={Environment}
                alt="Environment"
                className="w-32 h-32 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">Environment</h3>
              <p className="text-gray-600 text-center">
                All our teachers are important to us. We have a joyful and
                beautiful environment during work.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-10">
            {/* Card 4 */}
            <div className="flex flex-col items-center">
              <img
                src={TeachEnjoy}
                alt="Teach and Enjoy"
                className="w-32 h-32 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">Teach And Enjoy</h3>
              <p className="text-gray-600 text-center">
                We all want to have free time. You want to go for some holidays
                or have some other reason. We manage short leaves because we
                care about you.
              </p>
            </div>

            {/* Card 5 */}
            <div className="flex flex-col items-center">
              <img
                src={HelpOthers}
                alt="Help Others to Help Yourself"
                className="w-32 h-32 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">
                Help Others to Help Yourself
              </h3>
              <p className="text-gray-600 text-center">
                We all rise by lifting others. You have two hands, one for
                helping yourself and the other for others. You’ll experience new
                ways to learn and become better.
              </p>
            </div>
          </div>

          <button className="mt-10 px-6 py-3 bg-blue-500 text-white rounded-full font-medium hover:bg-blue-600 transition duration-300">
            Apply as Volunteer
          </button>
        </div>
      </div>
    </div>
  );
}

export default VolunteerPage;
