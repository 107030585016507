import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SearchResultsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("query");

  const [results, setResults] = useState({
    courses: [],
    blogs: [],
    lectures: [],
  });

  const [loading, setLoading] = useState(true);

  const pages = [
    { id: 1, title: "Home", description: "Welcome to our homepage.", path: "/" },
    { id: 2, title: "Courses", description: "Explore our courses library.", path: "/courses" },
    { id: 3, title: "Blogs", description: "Read our latest blog posts.", path: "/blogs" },
    { id: 4, title: "Blog Post", description: "Detailed view of a blog post.", path: "/blogpost/:blogpostId" },
    { id: 5, title: "Course Detail", description: "Detailed view of a course.", path: "/course/:courseId" },
    { id: 6, title: "Certificate Detail", description: "Details about course certificates.", path: "/course/:courseId/certificate/:certificateId" },
    { id: 7, title: "Exam Availability", description: "Check exam availability for certifications.", path: "/exam-availability/:certificationId" },
    { id: 8, title: "Lecture Detail", description: "View details of specific lectures.", path: "/lecture/:lectureId" },
    { id: 9, title: "About Us", description: "Learn more about our mission and team.", path: "/aboutus" },
    { id: 10, title: "Contact Us", description: "Reach out to us with your questions.", path: "/contactus" },
    { id: 11, title: "Licence Agreement", description: "Read our licence agreement.", path: "/licence-aggrement" },
    { id: 12, title: "Help", description: "Find help and support for your queries.", path: "/help" },
    { id: 13, title: "FAQ", description: "Frequently Asked Questions.", path: "/faq" },
    { id: 14, title: "Privacy Policy", description: "Understand our privacy practices.", path: "/privacy-policy" },
    { id: 15, title: "Volunteer", description: "Join our community as a volunteer.", path: "/volunteer" },
  ];
  

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);

      try {
        // Fetch courses
        const coursesResponse = await fetch("https://your-api-url.com/courses_library");
        const coursesData = await coursesResponse.json();

        // Fetch blogs
        const blogsResponse = await fetch("https://your-api-url.com/blogs");
        const blogsData = await blogsResponse.json();

        // Fetch lectures
        const lecturesResponse = await fetch("https://your-api-url.com/lectures");
        const lecturesData = await lecturesResponse.json();

        // Store all fetched data
        setResults({
          courses: coursesData.data || [],
          blogs: blogsData.data || [],
          lectures: lecturesData.data || [],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      setLoading(false);
    };

    fetchAllData();
  }, []);

  const filteredCourses = results.courses.filter((course) =>
    course.vendor_Name.toLowerCase().includes(query.toLowerCase())
  );

  const filteredBlogs = results.blogs.filter((blog) =>
    blog.title.toLowerCase().includes(query.toLowerCase())
  );

  const filteredLectures = results.lectures.filter((lecture) =>
    lecture.title.toLowerCase().includes(query.toLowerCase())
  );

  const filteredPages = pages.filter((page) =>
    page.title.toLowerCase().includes(query.toLowerCase()) ||
    page.description.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className="min-h-screen py-10 bg-gray-50">
      <div className="container px-4 mx-auto">
        <h2 className="mb-6 text-2xl font-bold text-gray-800">
          Search Results for <span className="text-indigo-600">"{query}"</span>
        </h2>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {filteredCourses.length === 0 &&
            filteredBlogs.length === 0 &&
            filteredLectures.length === 0 &&
            filteredPages.length === 0 ? (
              <p className="text-center text-gray-600">No results found.</p>
            ) : (
              <div className="space-y-8">
                {filteredPages.length > 0 && (
                  <div>
                    <h3 className="mb-4 text-xl font-semibold text-gray-800">Pages</h3>
                    <div className="space-y-4">
                      {filteredPages.map((page) => (
                        <div
                          key={page.id}
                          className="p-5 transition duration-300 bg-white rounded-lg shadow-md hover:shadow-lg"
                        >
                          <h4 className="mb-2 text-lg font-semibold text-gray-800">
                            <a href={page.path} className="text-indigo-600 hover:underline">
                              {page.title}
                            </a>
                          </h4>
                          <p className="text-gray-600">{page.description}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Other categories (Courses, Blogs, Lectures) */}
                {filteredCourses.length > 0 && (
                  <div>
                    <h3 className="mb-4 text-xl font-semibold text-gray-800">Courses</h3>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                      {filteredCourses.map((course) => (
                        <div
                          key={course.vendorId}
                          className="p-5 transition duration-300 bg-white rounded-lg shadow-md hover:shadow-lg"
                        >
                          <h4 className="mb-2 text-lg font-semibold text-gray-800">
                            {course.vendor_Name}
                          </h4>
                          <p className="text-gray-600">{course.detail}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchResultsPage;
